import defaultTheme from 'tailwindcss/defaultTheme';
import preset from '@aspect/shared/tailwind.preset.js';

/** @type {import('tailwindcss').Config} */
export default {
    content: [
        './resources/app/**/*.{ts,js,vue,blade.php}',
        './resources/consumer/**/*.{ts,js,vue,blade.php}',
        './resources/shared/**/*.{ts,js,vue,blade.php}',
        './resources/site/**/*.{ts,js,vue,blade.php}',
        '../../node_modules/@aspect/shared/**/*.{ts,js,vue,blade.php}',
    ],
    presets: [
        preset,
    ],
    theme: {
        extend: {
            fontFamily: {
                sans: ['Inter', ...defaultTheme.fontFamily.sans],
                display: ['Lexend', ...defaultTheme.fontFamily.sans],
                mono: ['Monocode', ...defaultTheme.fontFamily.mono],
            },
            backgroundImage: {
                'gradient-elipsis': 'radial-gradient(var(--tw-gradient-stops))',
            },
            animation: {
                'bounce-zoom': 'bounce-zoom 2s ease-in-out infinite',
            },
            keyframes: {
                'bounce-zoom': {
                    '0%, 100%': {
                        transform: 'scale(1)',
                    },
                    '50%': {
                        transform: 'scale(1.025)',
                    },
                },
            },
        },
    },
};
