import defaultTheme from 'tailwindcss/defaultTheme';
import forms from '@tailwindcss/forms';
import typography from '@tailwindcss/typography';

import animate from './tailwind/animate';
import textShadow from './tailwind/text-shadow';

const spacing = {
    '4.5': '1.125rem',
    '5.5': '1.375rem',
    '13': '3.25rem',
    '15': '3.75rem',
    '18': '4.5rem',
    '21': '5.25rem',
    '22': '5.5rem',
    '26': '6.5rem',
    '30': '7.5rem',
    '34': '8.5rem',
    '52': '13rem',
    '68': '17rem',
    '84': '21rem',
    '90': '22.5rem',
    'screen-1/2': '50vh',
    'prose': '80ch',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    '1/5': '20%',
    '2/5': '40%',
    '3/5': '60%',
    '4/5': '80%',
    ...defaultTheme.spacing,
};

/** @type {import('tailwindcss').Config} */
export default {
    darkMode: ['class'],
    safelist: ['dark'],
    theme: {
        extend: {
            zIndex: {
                60: 60,
            },
            lineHeight: {
                14: '3.5rem',
            },
            fontSize: {
                '3xs': ['10px', '16px'],
                '2xs': ['12px', '16px'],
                'xs': ['13px', '18px'],
            },
            fontWeight: {
                regular: 400, // An alias to the default 400, with proper naming.
            },
            colors: {
                'gray': {
                    50: '#fafafa',
                    100: '#f5f5f5',
                    150: '#f0f0f0', // Annnnnnnnnnd here we go.
                    200: '#e5e5e5',
                    300: '#d4d4d4',
                    400: '#a3a3a3',
                    500: '#737373',
                    600: '#525252',
                    700: '#404040',
                    800: '#262626',
                    900: '#171717',
                    950: '#111111',
                },
                'tenant': {
                    text: 'var(--tenant-text-color)',

                    50: 'var(--tenant-color-50)',
                    100: 'var(--tenant-color-100)',
                    // 200: 'var(--tenant-color-200)',
                    // 300: 'var(--tenant-color-300)',
                    400: 'var(--tenant-color-400)',
                    500: 'var(--tenant-color-500)',
                    600: 'var(--tenant-color-600)',
                    700: 'var(--tenant-color-700)',
                    // 800: 'var(--tenant-color-800)',
                    // 900: 'var(--tenant-color-900)',
                },
                'red': {
                    50: '#f7dede',
                    100: '#f7cfcf',
                    200: '#f4b9b9',
                    300: '#ee9b9b',
                    400: '#e26e6e',
                    500: '#d84f4f',
                    600: '#b63535',
                    700: '#932a2a',
                    800: '#762323',
                    900: '#632222',
                },
                'green': {
                    50: '#f0f9f0',
                    100: '#ddf0db',
                    200: '#b9e1b9',
                    300: '#76c27a',
                    400: '#59ae60',
                    500: '#379242',
                    600: '#277432',
                    700: '#1f5d29',
                    800: '#1b4a23',
                    900: '#173d1e',
                },
                'teal': {
                    50: '#effbfb',
                    100: '#d7f3f4',
                    200: '#b7e9eb',
                    300: '#98dce1',
                    400: '#4abac4',
                    500: '#30a1ab',
                    600: '#29818e',
                    700: '#296875',
                    800: '#2a5660',
                    900: '#284953',
                },
                'blue': {
                    50: '#f4f7fb',
                    100: '#e9edf5',
                    200: '#cedae9',
                    300: '#a2bbd7',
                    400: '#7096bf',
                    500: '#4f79a8',
                    600: '#3c608d',
                    700: '#324d72',
                    800: '#2c4360',
                    900: '#293a51',
                },
                'purple': {
                    50: '#f2f2fb',
                    100: '#e7e7f8',
                    200: '#d4d4f1',
                    300: '#bcbae7',
                    400: '#a69edb',
                    500: '#9385cf',
                    600: '#8b76c3',
                    700: '#715ba7',
                    800: '#5c4c87',
                    900: '#4d426d',
                },
                'linkedin': '#0077b5',
                'facebook': '#3b5998',
                'x-twitter': '#000000',
                'instagram': '#e4405f',
                'youtube': '#cd201f',
                'tiktok': '#69c9d0',
            },
            spacing,
            minHeight: spacing,
            maxWidth: spacing,
            margin: spacing,
            flexBasis: {
                '1/7': '14.2857143%',
                '2/7': '28.5714286%',
                '3/7': '42.8571429%',
                '4/7': '57.1428571%',
                '5/7': '71.4285714%',
                '6/7': '85.7142857%',
                '1/8': '12.5%',
                '2/8': '25%',
                '3/8': '37.5%',
                '4/8': '50%',
                '5/8': '62.5%',
                '6/8': '75%',
                '7/8': '87.5%',
            },
            boxShadow: {
                't-sm': '0 -1px 2px 0 rgb(0 0 0 / 0.05)',
                't': '0 -1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
                't-md': '0 -4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
                't-lg': '0 -10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
                't-xl': '0 -20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
                't-2xl': '0 -25px 50px -12px rgb(0 0 0 / 0.25)',
                'origin': '0 0 3px 0 rgb(0 0 0 / 0.1), 0 0 2px -1px rgb(0 0 0 / 0.1)',
                'origin-md': '0 0 6px -1px rgb(0 0 0 / 0.1), 0 0 4px -2px rgb(0 0 0 / 0.1)',
                'origin-lg': '0 0 15px -3px rgb(0 0 0 / 0.1), 0 0 6px -4px rgb(0 0 0 / 0.1)',
                'origin-xl': '0 0 25px -5px rgb(0 0 0 / 0.1), 0 0 10px -6px rgb(0 0 0 / 0.1)',
                'inner-md': 'inset 0 2px 4px 0 rgb(0 0 0 / 0.06)',
            },
            aspectRatio: {
                full: '4 / 3',
                wide: '16 / 9',
                ultrawide: '21 / 9',
                picture: '3 / 2',
                portrait: '2 / 3',
            },
            scale: {
                101: '1.01',
            },
            keyframes: {
                'accordion-down': {
                    from: { height: 0 },
                    to: { height: 'var(--radix-accordion-content-height)' },
                },
                'accordion-up': {
                    from: { height: 'var(--radix-accordion-content-height)' },
                    to: { height: 0 },
                },
                'collapsible-down': {
                    from: { height: 0 },
                    to: { height: 'var(--radix-collapsible-content-height)' },
                },
                'collapsible-up': {
                    from: { height: 'var(--radix-collapsible-content-height)' },
                    to: { height: 0 },
                },
                'blink': {
                    '20%, 80%': { opacity: 1 },
                    '50%': { opacity: 0 },
                },
                'bounce-zoom': {
                    '0%, 100%': {
                        transform: 'scale(1)',
                    },
                    '50%': {
                        transform: 'scale(1.025)',
                    },
                },
            },
            animation: {
                'accordion-down': 'accordion-down 0.2s ease-out',
                'accordion-up': 'accordion-up 0.2s ease-out',
                'collapsible-down': 'collapsible-down 0.2s ease-in-out',
                'collapsible-up': 'collapsible-up 0.2s ease-in-out',
                'blink': 'blink 1s ease-out infinite',
                'bounce-zoom': 'bounce-zoom 2s ease-in-out infinite',
            },
        },

        container: {
            center: true,
            padding: '1rem',
        },
    },

    plugins: [forms, typography, textShadow, animate],
};
