import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config.ts';

const { theme } = resolveConfig(tailwindConfig) as any;

export const remToPx = (rem: string | number, includeUnit = true): string | number => {
    const remValue = typeof rem === 'string' ? parseFloat(rem) : rem;
    const pxValue = remValue * 16;

    return includeUnit ? `${pxValue}px` : pxValue;
};

export default theme;
